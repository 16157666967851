<script setup>
const { img, action, to } = defineProps({
  img: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
})

const { optimizeImage } = useOptimizeImage()
const imageOptimized = computed(() => {
  return {
    alt: action,
    cover: true,
    ...optimizeImage(
      img,
      /* options */
      {
        /* If using local images instead of unsplash url, enable netlify provider */
        // provider:
        //     process.env.NODE_ENV === 'production'
        //       ? 'netlify'
        //       : null /* defaults to ipx or ipxStatic */,
        // placeholder: false, // placeholder image before the actual image is fully loaded.
      },
      true /* return bgStyles */,
    ),
  }
})
// const bgStyles = imageOptimized.value.bgStyles
</script>
<template>
  <div class="pb-4 px-4 rounded-3xl w-full sm:w-8/12 md:w-6/12 lg:w-4/12">
    <div
class="bg-cover bg-no-repeat flex pb-4 h-96 px-4 rounded-3xl bg-primary-700 dark:bg-primary-700"
      :style="bgStyles">

      <div class="group mx-auto w-5/6 text-white ">
        <div class="flex items-center justify-between flex-col mt-4">
          <BaseIcon :name="icon" class="text-3xl my-2" width="48px" />
          <h4 class="font-semibold my-2 text-center">
            {{ title }}
          </h4>
        </div>
        <div class="font-semibold my-2 text-center" v-html="text"/>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
