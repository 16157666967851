<script setup lang="ts">
  defineProps({
    rating: {
      type: Number,
      required: true,
    },
  })
  const maxRate = 5
</script>
<template>
 
 <div class="flex items-center">
  <template v-for="i in maxRate" :key="i">
    <svg v-if="i <= rating" class="w-3 h-3 fill-current text-yellow-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
    <svg v-else class="w-3 h-3 fill-current text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
  </template>
</div>

</template>
<style scoped></style>
