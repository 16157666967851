<script setup lang="ts">
  import { heroImageUrl } from '@/utils/hero'

  definePageMeta({
    // layout: 'default',
    // name: 'index',
    // alias: 'index',
    title: '#1 Rated Hibachi At Home Experience Nationwide',
    description: 'Now you can bring the hibachi experience home to your own backyard with HibachiX. ',
    navOrder: 1,
    type: 'primary',
    icon: 'i-mdi-home',
    // ogImage: 'images/ogImage.png', // url or local images inside public folder, for eg, ~/public/images/ogImage.png
  })

  const { optimizeImage } = useOptimizeImage()
  const heroImageOptimized = {
    alt: `hero`,
    cover: true,
    ...optimizeImage(
      heroImageUrl,
      /* options */
      {
        /* If using local images instead of unsplash url, enable netlify provider */
        // provider:
        //     process.env.NODE_ENV === 'production'
        //       ? 'netlify'
        //       : null /* defaults to ipx or ipxStatic */,
        placeholder: false, // placeholder image before the actual image is fully loaded.
        // provider: 'netlify'
      },
      true /* return bgStyles */,
    ),
  }

  const heroImage = heroImageOptimized.src
  const bgStyles = heroImageOptimized.bgStyles
</script>
<template>
  <div>
    <HeroSection />
    <!-- <CallToAction /> -->
    <HowItWorks class="mt-6"/>
    <!-- <MiniDivider /> -->
    <RegionListLite class="mt-6"/>
    <!-- <OurMission /> -->
    <!-- <MiniDivider /> -->
    <UserReviews class="mt-6"/>
    <!-- <UserStories/> -->
    <!-- <StatsSection /> -->
  </div>
</template>
<style scoped></style>
