<script setup lang="ts">
import { heroImageUrl } from '@/utils/hero'
import site from '~~/site'

const { optimizeImage } = useOptimizeImage()
const heroImageOptimized = {
  alt: `hero`,
  cover: true,
  ...optimizeImage(
    heroImageUrl,
    /* options */
    {
      /* If using local images instead of unsplash url, enable netlify provider */
      // provider:
      //     process.env.NODE_ENV === 'production'
      //       ? 'netlify'
      //       : null /* defaults to ipx or ipxStatic */,
      placeholder: false, // placeholder image before the actual image is fully loaded.
    },
    true /* return bgStyles */,
  ),
}

const heroImage = heroImageOptimized.src

const bgStyles = heroImageOptimized.bgStyles

</script>
<template>
  <div class="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden bg-center bg-cover bg-no-repeat" >
  <div class="absolute inset-0">
    <img :src="heroImageOptimized.src" :srcset="heroImageOptimized.srcset" :sizes="heroImageOptimized.sizes" alt="Background Image" class="object-cover object-center w-full h-full" >
    <div class="absolute inset-0 bg-black opacity-50"/>
  </div>
  
  <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
    <h1 class="text-5xl font-bold leading-tight mb-4">HIBACHI PARTY IN YOUR BACKYARD</h1>
    <p class="text-lg text-gray-300 mb-8">Book Your Backyard Hibachi Party Today!</p>

    <BaseButton
size="xl" label="Book Now" 
                to="/locations">
                <div class="inline-block ml-1 p-1.5 text-lg">Book Now</div>
              </BaseButton>

    <!-- <a href="#" class="bg-primary-400 text-gray-900 hover:bg-primary-300 py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Book Now</a> -->
  </div>
</div>


  <!-- <section class="container mx-auto pb-12 px-4">
    <div class="bg-center bg-cover bg-no-repeat blur-none rounded-3xl z-0" :style="bgStyles">
      <div class>
        <div class="lg:px-12 md:py-36 pb-6 pt-36 px-6 relative rounded-3xl">
          <div class="-mx-4 flex flex-wrap items-center mt-24 space-y-6 lg:space-y-0">
            <div class="px-4 w-full text-center">
              <h4 class="font-bold font-serif mb-1 text-primary-600 dark:text-primary-200 uppercase">
                HIBACHI PARTY IN YOUR BACKYARD
              </h4>
              <h1 class="capitalize leading-tight mb-2 text-white">
                {{ site.name }}
              </h1>
            
              <BaseButton size="xl" label="Book Now" 
                to="/booking">
                <div class="inline-block ml-1 p-1.5 text-lg">Book Now</div>
                <BaseIcon name="i-material-symbols-call-outline" height="28px" />
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</template>
<style scoped></style>
