<script setup lang="ts">
const callToActions = [
  {
    img: '/images/flame-black-large.png',
    title: 'PRICING',
    text: '$50 per Adult <br> $25 per Child under 12  <br> $500.00 Minimum for all parties  <br> <br>  *Price may vary by location.',
    icon: 'i-mdi-dollar'
  },
  {
    img: '/images/flame-black-large.png',
    title: 'PROTEIN CHOICES',
    text: '2 Per Person   <br><br> Chicken, Steak, Shrimp, Scallops, Salmon, Tofu, Filet Mignon +$5, Lobster Tail +$10  <br>  <br> Includes Salad, Fried Rice, and Vegetable',
    icon: 'i-mdi-utensils'
  },
  {
    img: '/images/flame-black-large.png',
    title: 'ADD ONS & EXTRAS',
    text: 'Additional protein costs: <br> Chicken (+$10), Steak (+$10), Shrimp (+$10), Scallops (+$10), Salmon (+$10), Tofu (+$10), Filet Mignon (+$15), Lobster Tail (+$15), Gyoza - 6pcs (+$10)',
    icon: 'i-fluent-collections-add-20-filled'
  },
  // {
  //   img: '/images/flame-black-large.png',
  //   title: 'WHAT SHOULD YOU PREPARE',
  //   text: 'We bring the Chef, Cooking Setup, and Food. You provide Tables and Chairs for your party, positioned around where our chef will set up to cook. Also provide your guests Eating Utensils, Plates and salad bowls, and Beverages of choice. Please note we cook outside only.',
  //   icon: 'i-material-symbols-table-bar'
  // },
]
</script>
<template>
  <section class="container mx-auto px-4">
    <div>
      <h3 class="text-primary-600 dark:text-primary-200 text-center uppercase">
        HOW IT WORKS
      </h3>
      <!-- <h3 class="capitalize mt-3 text-center">
        Small gesture for a big impact
      </h3> -->
      <p class="mt-4 text-center">
        Our team brings the hibachi grill and chef directly to your location, be it a backyard or any other event space.
        You simply need to set up tables, chairs, and ensure that dining utensils are available for your guests. Watch
        as our chef delights your party with an engaging cooking show, serving up a sumptuous meal everyone will enjoy.
      </p>
    </div>
    <div class="-mx-4 flex flex-wrap justify-center mt-12">
      <HowItWorksCard v-for="(callToAction, index) in callToActions" :key="index" :text="callToAction.text"
        :img="callToAction.img" :title="callToAction.title" :icon="callToAction.icon" />
    </div>
  </section>
</template>
<style scoped></style>
